import React, { useState } from "react"
import BannerHeader from "../components/bannerHeader"
import "../static/style/pageStyle/features.scss"


import { useStaticQuery } from "gatsby"
import { featureData } from "../services/helperFunctions.js"
import PryzmFeatures from "../components/pryzm-feature.js"
import { Helmet } from "react-helmet"

const Features = props => {
  const data = useStaticQuery(
    graphql`
      {
        feature {
          feature {
            journey {
              attributes {
                cmpName
                heading
                imageUrl
                dynamicCmp {
                  featureHeading
                  cards {
                    cardHeading
                    iconUrl
                    content
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const [activeTab, setActiveTab] = useState(0)
  const [dActiveTab, setDActiveTab] = useState(true)
  const handleClick = header => {
    setActiveTab(header)
    setDActiveTab(!dActiveTab)
    if (activeTab === header) {
      setDActiveTab(!dActiveTab)
    }
  }
 
  const [isOpen, setIsOpen] = useState({ 0: true });

  const toggleAccordion = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const { banner } = featureData(data)
  const title = "Features | Pryzm - A Data Reliability & Observability Platform";
  return (
    <>
      <div className={`platform-v3 features`}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >        <title>{title}</title>
      </Helmet>
        <div className="page-content">
          <div className="page-wrapper">
            <div className="banner">
              <BannerHeader header={banner.heading} />
            </div>
            <div className="frame">
              {/* <div className="sidebar">
                <img src={`${process.env.STRAPI_URL + banner.imageUrl}`} />
              </div> */}
              <div className="main-frame">
                <div className=" main-container">
                <div className="feature-heading">
                    <ul>
                      {banner.dynamicCmp.map((content, index) => (
                        <>
                          {/* className={`${
                                activeTab === index
                                  ? "list-heading-active"
                                  : "list-heading"
                              }` */}
                          <li
                            className={`${activeTab === index ? "active" : ""}`}
                            onClick={() => handleClick(index)}
                            key={index}
                          >
                            <h2 className="list-heading">
                              {content.featureHeading}
                            </h2>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                  <div className="feature-content">
                    {banner.dynamicCmp[activeTab].cards.map(
                      (content, index) => (
                        <PryzmFeatures content={content} index={index} />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-mobile">
              <div className="feature-heading-mobile">
                <ul>
                  {banner.dynamicCmp.map((content, index) => (
                    <>
                      <li onClick={() => toggleAccordion(index)} key={index}>
                        <h2
                          className={`${
                            isOpen[index] 
                              ? "list-heading-active"
                              : "list-heading"
                          }`}
                        >
                          {content.featureHeading}
                        </h2>
                      </li>
                      <div
                        className="feature-content-mobile"
                      >
                        {isOpen[index] && (
                          <>
                            {banner.dynamicCmp[index].cards.map(
                              (content, index2) => (
                                <PryzmFeatures
                                  content={content}
                                  index={index2}
                                />
                              )
                            )}
                            <p className="mobile-close-button" onClick={() => toggleAccordion(index)}></p>
                          </>
                        )}
                      </div>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Features
